import * as React from "react"
import { graphql } from "gatsby"
import { Container, Box, Text, Section, Flex, Subhead } from "../ui"
import {lightcolor, spancolor_2,bold,objecti, objective_img,lightcolor_3, marginTop, marginTop_1,paddingTop} from "../ui.css"
import { StaticImage } from "gatsby-plugin-image"

export default function Objective(props) {
    return (
        <>
        <Section style={{
            transform: "skewY(-6deg)",
            background: "radial-gradient(88.77% 152.19% at 12.8% -49.02%, #2B237C 0%, #251C72 31%, #0F054C 100%)", color: "#fff"
        }}>
            <Container  style={{ transform: "skewY(6deg)" }} className={paddingTop} width="narrow2" >
                <Flex gap={4} variant="responsive">

                    <Box width="half">
                        <Subhead className={spancolor_2}>
                            Objective
                        </Subhead>
                        <Text variant="lead" className={lightcolor_3}>Virtual Experience of various Accounting and Tax works
                        </Text>
                        <Flex gap={4} variant="responsive">
                            <Box>
                            <Text> <StaticImage  src='../../images/about_images/simulating_work_experience.png' alt="simulating_work_experience" /></Text>
                                <Text className={`${spancolor_2} ${bold}`}>Simulating work experience</Text>
                                <Text >Simulating work experience in Accounting and Tax fields.</Text>
                            </Box>
                            <Box className={marginTop_1}>
                           <Text><StaticImage   src='../../images/about_images/job_experience.png' alt="job_experience" /></Text>
                                <Text className={`${spancolor_2} ${bold}`}>Actual Job Experience</Text>
                                <Text >Online Platform to provide actual Job Experience.</Text>
                            </Box>


                        </Flex>
                        <Flex gap={4} variant="responsive">
                            <Box>
                             <Text>   <StaticImage   src='../../images/about_images/accounting_works.png' alt="accounting_works" /></Text>
                                <Text className={`${spancolor_2} ${bold}`}>Actual Accounting work</Text>
                                <Text >Experiencing actual accounting works of Industry / corporate.</Text>
                            </Box>
                            <Box className={marginTop_1}>
                        <Text>        <StaticImage  src='../../images/about_images/practical_training.png' alt="practical_training" /></Text>
                                <Text className={`${spancolor_2} ${bold} `}>Work in Virtual Company</Text>
                                <Text >Gain 3 years of strong Experience in virtual company.</Text>
                            </Box>

                        </Flex>
                    </Box>
                    <Box width="half" order={null}>
                       <Text className={objective_img}> <StaticImage  src='../../images/about_images/objective.png' alt="objective" width={700}/></Text>
                    </Box>
                </Flex>

            </Container>
        </Section>
       
        </>
        
    )
}
