import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Container, Section, Box, PlatformHeading, PlatformSubhead,Text,Flex } from "../ui"
import { about_hero, about_hero_img } from "../ui.css"


export default function AboutHero(props) {
  return (
    <>
      <Section className={about_hero}>
      <Container width="narrow2" >
                    <Flex gap={4} variant="responsive">
                        <Box>
                            <PlatformHeading>
                            About Our Company
                            </PlatformHeading>
                            <PlatformSubhead>
                            A unique skill tech startup
                            </PlatformSubhead>
                        </Box>
                    </Flex>

                </Container>

      </Section>
      <Section></Section>
      <Container className={about_hero_img}  width="narrow2" >
        <Text>
        <StaticImage src='../../images/about_images/team_img.png' alt="team_img" />
        </Text> 
      </Container>

      

    </>
  )
}

// export const query = graphql`
//   fragment AboutHeroContent on AboutHero {
//     id
//     heading
//     text
//     image {
//       id
//       gatsbyImageData
//       alt
//     }
//   }
// `
