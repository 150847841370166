import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import AboutHero from "../components/about_page/about-hero"
import AboutUs from "../components/about_page/about-us"
import Ideology from "../components/about_page/ideaology"
import Our_focus from "../components/about_page/our_focus"
import Objective from "../components/about_page/objective"
import Road_map from "../components/about_page/road_map"
import AboutNavList from "../components/about/about_nav_items"
import Industy_challanges from "../components/about_whyskill_elevator/Industry_challangs"
import Step_taken from "../components/about_whyskill_elevator/steps_taken"
import Would_help from "../components/about_whyskill_elevator/would_help"
import ContactUS from "../components/contact-us"

const AboutPage = () => (
  <Layout navName="About" width="narrow2">
    <Seo title="About" />
    <AboutHero />
    <Ideology />
    <Objective />
    <Our_focus />
    <Industy_challanges />
    <Step_taken />
    <Would_help />
    <Road_map />
    <ContactUS />
  </Layout>
)

export default AboutPage
