import * as React from "react"
import { graphql } from "gatsby"
import { Container, Box, Text, Section, Flex, Subhead } from "../ui"
import { black_1, bold, lightcolor_1, marginTop, marginTop_1, spancolor } from '../ui.css'
import { StaticImage } from "gatsby-plugin-image"

export default function Our_focus(props) {
    return (
        <Section >
            <Container className={black_1} width="narrow2">
                <Flex gap={4} variant="responsive">
                    <Box className={marginTop_1}>
                        <Subhead>
                            Our <span className={spancolor}> Focus</span>&nbsp;<StaticImage src="../images/skill_icon.png" transformOptions={{ fit: "cover", cropFocus: "attention" }} alt="skill_elevator_icon" width={20} style={{ marginTop: "1%" }} />
                        </Subhead>
                        <Text variant="lead" className={lightcolor_1}>Providing learning opportunities to all commerce students and enabling them to gain work<br /> experience while they are studying, or we support them in seeking jobs faster.</Text>
                    </Box>
                </Flex>
                <Flex gap={4} variant="responsive">
                    <Box style={{ marginTop: "10%" }}>
                        <StaticImage src='../../images/about_images/business_laboratory.png' alt="business_laboratory" />
                        <Text className={bold}>Business Laboratory</Text>
                        <Text className={lightcolor_1}>Work with colleges and offer them to setup 'Business Laboratories' to their commerce students.</Text>
                    </Box>
                    <Box style={{ marginTop: "-17%" }}>
                        <StaticImage src='../../images/about_images/ca.png' alt="ca" width={200} />
                        <Text className={bold}>1st Step to CA</Text>
                        <Text className={lightcolor_1}>Getting practiced with bizzlab
                            is the 1st step in the pipeline
                            to CA</Text>
                    </Box>
                    <Box style={{ marginTop: "10%" }}>
                        <StaticImage src='../../images/about_images/training_platform.png' alt="training_platform" />
                        <Text className={bold}>Induction Training <br />Platform</Text>
                        <Text className={lightcolor_1}>Work with HRs in every corporate to make our module as an Induction Training Platform to offer their new joiners as First Job Training</Text>
                    </Box>
                    <Box style={{ marginTop: "-17%" }}>
                        <StaticImage src='../../images/about_images/fill_vacancy.png' alt="fill_vacancy" width={200} />
                        <Text className={bold}>Fill Vacancy</Text>
                        <Text className={lightcolor_1}>Work with Recruitment firms to consider our trained people in filling their vacancy needs.</Text>
                    </Box>
                </Flex>

            </Container>
        </Section>
    )
}
