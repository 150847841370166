import * as React from "react"
import { graphql } from "gatsby"
import { Container, Box, Text, Section, Flex, Subhead, FlexList } from "../ui"
import { StaticImage } from "gatsby-plugin-image"
import { spancolor, Ideology_img, lightcolor_1,lightcolor_4, bold, marginTop, black_color,text_justify, marginBottom_4, marginBottom_5, marginTop_1 } from "../ui.css";

export default function Ideology(props) {
    return (
        < >
            <Container style={{ color: "#081F32" }}  width="narrow2" className={marginTop_1}>
                <Flex gap={4} variant="responsive">
                    <Box order={null}>
                        <Text className={Ideology_img}><StaticImage src='../../images/about_images/about_Ideology.png' alt="Ideology" /></Text>

                    </Box>
                    <Box>
                        <Subhead >
                            Ideology <StaticImage src="../images/skill_icon.png" transformOptions={{ fit: "cover", cropFocus: "attention" }} alt="skill_elevator_icon"  width={20} style={{marginTop:"2%"}}/>

                        </Subhead>
                        <Text className={`${lightcolor_4} ${text_justify}`}>Skill & Employablity Enhancement mission</Text>
                        <Text variant="lead" className={`${lightcolor_4} ${text_justify}`}> Bizzlab is the product of Skill-Elevator Technologies Pvt Ltd was founded on 16th August 2021 by a team of industry experts with the mission of skill and Employability enhancement.  Before incorporation, the experts spent more than two years concluding and drafting the concept. These experts agreed to form an entity and became founders to run this project. It is a web-based platform designed for college students and job seekers. 
                        </Text>
                        <Flex gap={4} variant="responsive" className={marginTop}>
                            <Box>
                                <FlexList gap={4} variant="responsive">
                                    <li>
                                        <Text><StaticImage src='../../images/about_images/practical_training.png' alt="Ideology" width={100}/> <br/> &nbsp;</Text>
                                    </li>
                                    <li> <Text className={`${bold} ${black_color}`}>Practical training software for Accounts & tax</Text></li>
                                </FlexList>
                            </Box>
                            <Box>
                                <FlexList gap={4} variant="responsive">
                                    <li>
                                        <Text><StaticImage src='../../images/about_images/Virtual.png' alt="Virtual"  width={70}/><br/> &nbsp;</Text>
                                    </li>
                                    <li>   <Text className={`${bold} ${black_color}`}> Virtual/simulated work experience in commerce</Text></li>
                                </FlexList>
                            </Box>
                        </Flex>
                        <Flex gap={4} variant="responsive" className={`${marginTop} ${marginBottom_5}`}>
                            <Box>
                                <FlexList gap={4} variant="responsive">
                                    <li>
                                        <Text> <StaticImage src='../../images/about_images/skill_education.png' alt="Ideology" width={70} /><br/> &nbsp;<br/> &nbsp;</Text>
                                    </li>
                                    <li  ><Text className={`${bold} ${black_color}`}> Digital platform for skill education </Text><br/> &nbsp;</li>
                                </FlexList>
                            </Box>
                            <Box>
                                <FlexList gap={4} variant="responsive">
                                    <li>
                                        <Text><StaticImage src='../../images/about_images/web_application.png' alt="Virtual" width={100} /><br/> &nbsp;<br/> &nbsp;</Text>
                                    </li>
                                    <li > <Text className={`${bold} ${black_color}`}>India’s 1st web application to simulate industry works in virtual environment </Text></li>
                                </FlexList>
                            </Box>
                        </Flex>
                    </Box>
                </Flex>
            </Container>
        </>
    )
}
