import * as React from "react"
import { graphql } from "gatsby"
import { Container, Box, Text, Section, Flex, Subhead, SubheadSmall, MediumBoldText, Card } from "../ui"
import { StaticImage } from "gatsby-plugin-image"
import { Bold } from "react-feather"
import { bizzlab, spancolor, road_map_img, bold, marginBottom, marginBottom_3,road_map_card } from "../ui.css"
export default function Road_map(props) {
    return (

        <Container style={{ color: "#111C39" }} width="narrow2">

            <Flex gap={2} variant="responsive">
                <Box style={{ marginTop: "6%" }}>
                    <Subhead className={bizzlab}>
                        Roadmap
                    </Subhead>
                    <MediumBoldText variant="lead" className={`${bizzlab} ${bold}`}>Virtual Experience of various Accounting and Tax works
                    </MediumBoldText>
                </Box>
            </Flex>

            <Flex gap={4} variant="responsive">
                <Box>
                    <Text ><StaticImage src='../../images/about_images/roadmap.png' alt="roadmap" className={road_map_img} /></Text>
                </Box>
                <Box className={marginBottom_3}>
                    <Flex gap={4} variant="responsive">
                        <Box>
                            <Card className={road_map_card} >
                            <Text> <StaticImage src='../../images/about_images/number_icon_1.png' alt="number_icon_1" /></Text>
                            <Text className={bold}>
                                
                                To offer simulated job experience to 1million commerce students in 3 years and help them to get faster and better job.
                            </Text>
                            </Card>
                        </Box>
                        <Box>
                            <Card className={road_map_card}>
                            <Text>  <StaticImage
                                src='../../images/about_images/number_icon_2.png' alt="number_icon_2" /></Text>
                            <Text className={bold}>
                                <Container>
                                To setup Business Lab in more than 10,000 colleges in 3 years.
                                </Container>
                            
                            </Text>
                            </Card>
                        </Box>
                    </Flex>
                    <Flex gap={4} variant="responsive">
                        <Box>
                            <Card className={road_map_card}>
                            <Text>  <StaticImage
                                src='../../images/about_images/number_icon_3.png' alt="number_icon_3" /></Text>
                            <Text className={bold}>To enable at least 10,000 CA firms to equip themselves for training to their Article Students using our platforms.
                            </Text>
                            </Card>
                        </Box>
                        <Box>
                            <Card className={road_map_card}>
                            <Text>  <StaticImage
                                src='../../images/about_images/number_icon_4.png' alt="number_icon_4" /></Text>
                            <Text className={bold}> To onboard at least 1,000 corporates to use our platform for their fresher training program.
                            </Text>
                            </Card>
                        </Box>
                    </Flex>
                </Box>
            </Flex>
        </Container>

    )
}
