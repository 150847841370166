import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import {
    Container, Section, Flex, Box, Text, SubheadSmall, Button,
    ContactCard, ContactCardHeader, ContactCardBody, FlexList, MediumBoldText, Heading, Subhead
} from './ui';
import { contactButton, inputTextBox, lightcolor, spancolor_5,lightcolor_2,contact_bg  } from './ui.css';

const ContactUS = () => {
    return (
        <Section className={contact_bg} >
            <Container width='narrow2'>
                <Flex gap={1} variant="responsive">
                    <Box>
                        <Subhead className={spancolor_5 }>Contact our Team</Subhead>
                        <Text className={lightcolor_2}>Upon receiving your message, <br/>we will contact you within 24 hours.	</Text>
                    </Box>
                    <Box>
                        <ContactCard>
                            <ContactCardHeader>
                                <Flex gap={1} variant="responsive">
                                    <FlexList>
                                        <li>
                                            <StaticImage src="../images/email_box.png" alt="email_box" />
                                        </li>
                                        <li>
                                            <MediumBoldText>We’re happy to answer your questions and get you acquainted with BizzLab.</MediumBoldText>
                                        </li>
                                    </FlexList>
                                </Flex>
                            </ContactCardHeader>
                            <ContactCardBody>
                                <Flex gap={4} variant="responsive">
                                    <Box>
                                        <input type="text" className={inputTextBox} placeholder='FirstName' />
                                    </Box>
                                    <Box>
                                        <input type="text" className={inputTextBox} placeholder='LastName' />
                                    </Box>
                                </Flex>
                                <Flex gap={4} variant="responsive">
                                    <Box>
                                        <input type="text" className={inputTextBox} placeholder='Email' />
                                    </Box>
                                    <Box>
                                        <input type="text" className={inputTextBox} placeholder='PhoneNumber' />
                                    </Box>
                                </Flex>
                                <br />
                                <Flex gap={4} variant="responsive">
                                    <Box>
                                        <SubheadSmall style={{ color: '#081F32' }}>Optional</SubheadSmall>
                                        <textarea rows="6" className={inputTextBox} placeholder=' Your Message' />
                                    </Box>
                                </Flex>
                                <Button className={contactButton}>SUBMIT</Button>
                            </ContactCardBody>
                        </ContactCard>
                    </Box>
                </Flex>



            </Container>
        </Section>
    )
}
export default ContactUS;